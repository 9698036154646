@use "src/_app/styles/mixins.scss" as *;
.iframe__wrapper {
  width: 100%;
  height: 400px;
  overflow: hidden;
  border: 0;

  @include respond(tablet) {
    height: 325px;
  }

  @include respond(tabletS) {
    height: 260px;
  }

  @include respond(mobileL) {
    height: 230px;
  }

  iframe {
    width: 100%;
    height: 100%;
  }
}
