@use "src/_app/styles/mixins.scss" as *;
.share {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  align-items: flex-start;
  width: 100%;
  color: var(--primary);

  @include respond(tablet) {
    max-width: 343px;
  }

  &__title {
    text-align: start;

    @include tSubtitle_Semibold;
    margin: 0;
  }

  &__list {
    display: flex;
    flex-direction: row;
    column-gap: 24px;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;

    .list__item {
      width: 38px;
      height: 38px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      background: var(--bg_2);
      color: var(--primary);
      border-radius: 50%;
      cursor: pointer;
      border: none;

      @include transition;

      &:hover {
        background: var(--bg_icon_hover);
      }

      &:active {
        background: var(--bg_icon_active);
      }
    }
  }
}
